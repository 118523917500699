import { Injectable } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentCheckService {

  constructor(private modalService: ModalService, private alertService: AlertService) {}

  checkPaymentStatus(): void {
    const pendingPayment = localStorage.getItem('pendingPayment') === 'true';
    const upcomingBillsWarning = localStorage.getItem('upcomingBillsWarning') === 'true';
  
    if (pendingPayment) {
      this.modalService.openModal('paymentModal');
    }

    // if (upcomingBillsWarning) {
    //   this.alertService.showAlert('Facturas por vencer', 'Tienes facturas pendientes de pago. Por favor, revísalas.', 'warning');
    // }
  }
}
